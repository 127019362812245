<template>
  <v-card class="list-submodules mt-2 elevation-12">
    <vue-scroll>

      <v-list min-width="300" dense max-height="240" >
        <sub-module
            v-for="(subModule, j) in subModules"
            :key="j"
            :subModule="subModule"
            :module="module"
        ></sub-module>
      </v-list>

    </vue-scroll>

  </v-card>
</template>

<script>
export default {
  name: "SubModuleMenu",
  components: {
    SubModule: () => import("@/Components/Views/Modules/Elements/SubModule")
  },
  props: {
    subModules: [Array, Object],
    module: [Array, Object]
  }
}
</script>

<style scoped>

</style>